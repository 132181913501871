import * as React from "react"

import LayoutWhite from "../components/layoutWhite"

const mediosDePagoPage = () => (
  <LayoutWhite
	seccion="mediosDePago"	
	title="Payment Methods"	
	linkTo="experienciaContainer"
	>		
			<div id="whiteContainer" className="row">
				<div className="col-12"
						data-aos='fade-up'
						data-aos-duration="600" data-aos-once="true">
					<h1 className="">Payment Methods</h1>
					<ul>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cash.</li>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Checking account.</li>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Online payment through Mercado Pago (all payment methods).</li>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Credit card payments.</li>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Bank transfers.</li>
						<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Customized collections.</li>
					</ul>
				</div>			
			</div>
  </LayoutWhite>
)

export default mediosDePagoPage